<template>
  <div class="advertising_container">
    <div class="advertising_top">
      <div
        v-for="item in advertisingTab"
        :key="item.key"
        :class="['top_item', current === item.key ? 'act' : '']"
        @click="handleClickMenu(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="advertising_bottom">
      <InvoicingManagement v-if="current[0] === '1'" />
      <InvoicingQualification v-if="current[0] === '2'" />
    </div>
  </div>
</template>
<script>
import InvoicingManagement from './InvoicingManagement'
import InvoicingQualification from './InvoicingQualification'
export default {
  name: 'Invoice',
  components: { InvoicingManagement, InvoicingQualification },
  data () {
    return {
      current: '1',
      advertisingTab: [
        { name: '开票管理', key: '1' },
        { name: '开票资质', key: '2' }
      ]
    }
  },
  watch: {
    $route: {
      handler (val) {
        const query = val.query
        if (query.target === 'qualification') {
          this.current = '2'
        } else {
          this.current = '1'
        }
      },
      immediate: true
    }
  },
  created () {},
  methods: {
    handleClickMenu (item) {
      this.current = item.key
      this.$router.push({
        path: '/accountInfo/invoice?target=qualification',
        query: {
          target: item.key === '1' ? '' : 'qualification'
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.text-right {
  text-align: right;
}
.m-b-0 {
  margin-bottom: 0px;
}
.m-t-20 {
  margin-top: 20px;
}

.m-r-10 {
  margin-right: 10px;
}
.p-0 {
  padding: 0;
}
.advertising_container {
  margin: 0 10px;
  border-radius: 5px;
  background: #fff;
  font-family: Microsoft YaHei;
  .advertising_top {
    padding: 20px;
    border-radius: 5px 5px 0 0;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e6e6e6;
    .top_item {
      font-size: 14px;
      color: #333333;
      margin: 8px 8px 0;
      padding: 0 14px;
      border-radius: 10px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
    }
    .act {
      color: @primary-color;
      border: 1px solid #b3c7ff;
      background: fade(@primary-color, 10%);
    }
  }
  .advertising_bottom {
    min-height: 70vh;
  }
}
</style>
